<template>
  <v-card>
    <v-card-title>
      Expressões Regulares para tratamento das imagens (REGEX)</v-card-title
    >
    <v-card-text>
      <v-card>
        <v-card-title>
          {{ regex.id ? "Editar" : "Nova" }} REGEX
          <v-spacer></v-spacer>
          <v-btn @click="showForm = !showForm" color="warning"
            >{{ showForm ? "Esconder" : "Mostrar" }} Formulário</v-btn
          >
        </v-card-title>
        <v-card-text v-show="showForm">
          <v-row>
            <v-col>
              <v-text-field
                label="Nome do Regex"
                v-model="regex.name"
                hint="Nome ou a finalidade do regex"
                persistent-hint
                outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="Regex"
                v-model="regex.regex"
                hint="String do regex"
                persistent-hint
                outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                label="Flags"
                v-model="regex.flags"
                hint="Flags do Regex"
                persistent-hint
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-autocomplete
                chips
                small-chips
                clearable
                multiple
                deletable-chips
                v-model="regex.postionsToReturn"
                :items="indexes"
                outlined
                label="Resultados retornados"
                hint="Selecione quais resultados da expressão que serão retornados"
                persistent-hint
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-autocomplete
                v-model="regex.attributeName"
                :items="atrributesNames"
                outlined
                label="Nome do Atributo"
                hint="Selecione o Nome do Atributo que sera retornado"
                persistent-hint
              ></v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-checkbox label="Todos os items?" v-model="regex.allResults"></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-spacer></v-spacer>
            <v-btn color="warning" @click="clearForm">Cancelar</v-btn>
            <v-btn class="mx-2" color="success" @click="save">Salvar</v-btn>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="my-2">
        <v-card-title>
          Regex Cadastrados <v-spacer></v-spacer>
          <v-btn @click="$store.dispatch('loadRegexs')"
            >Atualizar</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-simple-table height="500px">
            <thead>
              <tr>
                <th class="text-left">ID</th>
                <th class="text-left">Nome</th>
                <th class="text-left">Regex</th>
                <th class="text-left">Flags</th>
                <th class="text-left">Posições</th>
                <th class="text-left">Atributo</th>
                <th class="text-left">Cadastro</th>
                <th class="text-left">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in getRegexs" :key="index">
                <td>{{ item.id }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.regex }}</td>
                <td>{{ item.flags }}</td>
                <td>{{ item.postionsToReturn }} Todos:({{ item.allResults ? "Sim": "Não" }})</td>
                <td>{{ item.attributeName }}</td>
                <td>{{ new Date(item.createdAt).toLocaleString() }}</td>
                <td>
                  <v-icon small @click="del(item)" class="m-1">
                    mdi-trash-can
                  </v-icon>
                  <v-icon small @click="edt(item)"> mdi-lead-pencil </v-icon>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "regex",
  components: {
  },
  data() {
    return {
      showForm: false,
      regex: {
        buyOrderItems: [{}],
        postionsToReturn:[],
        allResults:true
      },
      indexes: [],
      postionsToReturn:[],
      atrributesNames:[
        {value:'amount', text:"(amount) - usado para quantidade do produto"},
        {value:'name', text:"(name) - usado para nome do produto"},
        {value:'providerCode', text:"(providerCode) - usado para codigo do fornecedor"},
      ]
    };
  },
  computed: {
    ...mapGetters([
      "getRegexs",
      "getOrders",
      "getConstructionNameById",
      "getProviderNameById",
      "getConstructionsSelect",
      "getProvidersSelect",
    ]),
  },
  methods: {
    save() {
      // this.regex.postionsToReturn = ""
      // this.postionsToReturn.forEach((e,i)=>{
      //     this.regex.postionsToReturn += `${i ==0 ? '': ','}${e}`
      // })
      this.$store.dispatch("saveRegex", this.regex);
    },
    clearForm() {
      this.regex = {};
      this.postionsToReturn = []
    },
    del(e) {
      this.$store.dispatch("deletRegex", e);
    },
    edt(e) {
      this.regex = e;
      // this.postionsToReturn = []
      // if(e.postionsToReturn){
      //   if(e.postionsToReturn.includes(',')){
      //     this.postionsToReturn = e.postionsToReturn.split(',')
      //   }else{
      //     this.postionsToReturn.push(e.postionsToReturn)
      //   }
      // }else{
      //   this.postionsToReturn = []
      // }
      this.showForm = true;
    },
    addItem() {
      this.regex.buyOrderItems.push({});
    },
    delItem(index) {
      this.regex.buyOrderItems.splice(index, 1);
    },
  },
  mounted() {
    this.$store.dispatch("loadRegexs");
    for (let index = 0; index < 100; index++) {
      this.indexes.push({ value: `${index}`, text: `Posição - ${index}` });
    }
  },
};
</script>

<style>
</style>