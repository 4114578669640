<template>
  <div class="home ma-2" ref="top">
    <v-card class="ma-2">
      <v-card-title>Selecione uma Imagem</v-card-title>
      <v-card-text class="overflow-auto">
        <v-row>
          <v-col>
            <v-file-input
              v-model="img"
              placeholder="Upload da sua foto"
              prepend-icon="mdi-image-area"
              @change="pickFile"
              accept="image/png, image/jpeg, image/bmp camera"
              ref="file"
            >
            </v-file-input>
          </v-col>
          <v-col cols="2">
            <v-btn @click="send" color="success">Ler Imagem</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-autocomplete
              chips
              clearable
              multiple
              deletable-chips
              v-model="regexId"
              :items="getRegexsSelect"
              outlined
              label="Selecione a expressão regular para validar os dados"
            ></v-autocomplete>
          </v-col>
          
        </v-row>
        <v-row>
          <v-col>
            <v-img
              contain
              class="d-flex justify-center"
              id="avatar"
              :src="picture"
              max-height="800"
            ></v-img>
          </v-col>
        </v-row>
        <v-row v-show="response.length > 0">
          <v-spacer></v-spacer>
          <v-btn @click="backTop" color="primary">Voltar ao Topo</v-btn>
        </v-row>
        <p v-show="responseTime">
          <b>Tempo de Resposta: {{ responseTime }} ms</b>
        </p>
        <v-row>
          <v-col>
            <pre ref="response">{{ response }}</pre>
          </v-col>
        </v-row>
        <v-row v-show="regexResponse.length > 0">
          <v-col>
            <b>Resposta do Regex</b><br />
            <code>
              {{ regexResponse }}
            </code>
          </v-col>
        </v-row>
        <v-row v-show="response.length > 0">
          <v-spacer></v-spacer>
          <v-btn @click="backTop" color="primary">Voltar ao Topo</v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Tesseract",
  data: () => ({
    img: null,
    response: "",
    responseTime: 0,
    picture: null,
    regexId: 0,
    regexResponse: [],
    indexes:[]
  }),
  computed: {
    ...mapGetters(["getRegexsSelect"]),
  },
  methods: {
    pickFile() {
      let file = this.img;
      if (file) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.picture = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    send() {
      let formData = new FormData();
      this.response = "";
      this.responseTime = 0;
      this.regexResponse = [];
      formData.append("picture", this.img);
      formData.append("regexid", this.regexId);
      this.$http
        .post("/tesseract", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          if (resp.status < 299) {
            this.$store.dispatch("resetSnack");
            this.$store.dispatch("showSuccessSnack", "Imagem Decodificada");
            this.response = resp.data.result;
            this.responseTime = resp.data.time;
            if (resp.data.regexResponse) {
              this.regexResponse = resp.data.regexResponse;
            }
            this.$vuetify.goTo(this.$refs.response, {
              duration: 300,
              offset: 0,
              easing: "easeInOutCubic",
            });
          }
        });
    },
    pasted(IMG) {
      // mostra a imagem no preview e faz a requisição
      this.img = IMG;
      if (IMG) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.picture = e.target.result;
          this.send();
        };
        reader.readAsDataURL(IMG);
      }
    },
    backTop() {
      this.$vuetify.goTo(this.$refs.top, {
        duration: 300,
        offset: -10,
        easing: "easeInOutCubic",
      });
    },
  },
  created() {
    // usado para pegar o evento de colar
    this.$store.dispatch("loadRegexs");
    const VUE = this;
    document.onpaste = function (event) {
      const items = (event.clipboardData || event.originalEvent.clipboardData)
        .items;
      for (let index in items) {
        const item = items[index];
        if (item.kind === "file") {
          let blob = item.getAsFile();
          VUE.pasted(blob);
        }
      }
    };
  },
};
</script>
